@if (toast) {
	<div
		@fade
		[ngClass]="borderColors[toast.type]"
		class="flex space-x-5 fixed z-[99999] opacity-100 translate-y-0 right-3 p-4 min-w-[24rem] border-l-[6px] bg-white rounded shadow-md"
	>
		@if (toast.showIcon) {
			<div class="flex icon align-items-center">
				<app-icons
					[iconName]="icon[toast.type]"
					[ngClass]="iconColors[toast.type] + ' text-3xl'"
				/>
			</div>
		}
		<div class="flex-1 space-y-1">
			<div class="font-bold">
				{{ toast.title | translate }}
			</div>
			@if (toast.message) {
				<div>
					{{ toast.message | translate }}
				</div>
			}
		</div>
	</div>
}
